import klockord from "../constants/klockord.js";

const timeWord = (timma, minut) => {
  const iMinut =
    minut === 0
      ? 0
      : minut === 1
      ? 1
      : minut === 15
      ? 2
      : minut < 25
      ? 3
      : minut < 30
      ? 4
      : minut === 30
      ? 5
      : minut < 40
      ? 6
      : minut === 45
      ? 7
      : minut === 59
      ? 8
      : 9;

  const _minut =
    iMinut === 0
      ? minut
      : iMinut === 1
      ? iMinut
      : iMinut === 2
      ? iMinut
      : iMinut === 3
      ? minut
      : iMinut === 4
      ? 30 - minut
      : iMinut === 5
      ? iMinut
      : iMinut === 6
      ? minut - 30
      : iMinut === 7
      ? iMinut
      : iMinut === 8
      ? iMinut
      : 60 - minut;

  var _24timma;
  if (iMinut < 4) {
    _24timma = timma;
  } else {
    _24timma = timma + 1;
    if (_24timma === 24) _24timma = 0;
  }

  const iTimma = _24timma === 0 ? 0 : _24timma > 6 && _24timma < 20 ? 2 : 3;

  const _12timma = _24timma > 12 ? _24timma - 12 : _24timma;

  const ord = ["Klockan är"];

  if ([0, 1, 2, 5, 7, 8].includes(iMinut)) {
    ord.push(klockord.minuter[iMinut]);
  } else {
    _minut < 10
      ? ord.push(klockord.enTal[_minut])
      : _minut < 20
      ? ord.push(klockord.tonTal[_minut - 10])
      : ord.push(
          klockord.tioTal[Math.floor(_minut / 10)] +
            klockord.enTal[_minut - Math.floor(_minut / 10) * 10]
        );
    ord.push(klockord.minuter[iMinut]);
  }

  if (iTimma < 2) {
    ord.push(klockord.timmar[iTimma]);
  } else {
    _12timma < 10
      ? ord.push(klockord.enTal[_12timma])
      : _12timma < 20
      ? ord.push(klockord.tonTal[_12timma - 10])
      : ord.push(
          klockord.tioTal[Math.floor(_12timma / 10)] +
            klockord.enTal[_12timma - 20]
        );
    ord.push(klockord.timmar[iTimma]);
  }

  return ord.join(" ");
};

export default timeWord;
