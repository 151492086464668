import React from "react";
import { Spring } from "react-spring/renderprops";
import ola from "../assets/Ola.png";

export const About = () => (
  <Spring
    from={{ opacity: 0, marginTop: 5000 }}
    to={{ opacity: 1, marginTop: 0 }}
  >
    {(props) => (
      <div className="container grid" style={props}>
        <div className="box dimmed">
          <article className="media">
            <div className="media-left">
              <figure className="image is-64x64">
                <img src={ola} alt="Ola" />
              </figure>
            </div>
            <div className="media-content">
              <p className="title">Om mig</p>
              <p className="subtitle">
                <a href="mailto:ola@eldstorpsolutions.se">Ola Gustafsson</a>
              </p>
              <p>
                <span className="box">
                  <span className="icon">
                    <i className="fas fa-quote-left"></i>
                  </span>
                  Det har jag aldrig provat förut, så det klarar jag säkert!
                  <span className="icon">
                    <i className="fas fa-quote-right"></i>
                  </span>
                </span>
              </p>
              <p className="content">
                <br />
                <p>
                  Så säger Pippi Långstrump och så står det in min bok. Jag har
                  alltid varit intresserad av datorer i allmänhet och
                  programmering i synnerhet. Det intresset har alltid varit en
                  bland flera hobbyer. Jag har också haft möjlighet att jobba
                  med det i olika former. Inte sällan som en reaktion på ett
                  behov eller en idé om ett systemstöd, integration eller
                  automation. Det har alltid varit roligt och jag har sett det
                  som en personlig utveckling att hela tiden bli bättre på
                  detta. För att påskynda den processen har jag också genom
                  studier bättrat på mina teoretiska kunskaper. Jag kommer att
                  fortsätta arbeta med utveckling av system och mjukvaror. Prova
                  nya saker. Klara av dem!
                </p>
                <p>
                  <span className="icon">
                    <i className="fas fa-copyright"></i>
                  </span>
                  <span>Eldstorp, </span>
                  <time dateTime="2020-06-06">Juni 2020</time>
                </p>
              </p>
            </div>
          </article>
        </div>
      </div>
    )}
  </Spring>
);
