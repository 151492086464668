import React from "react";

export const TileOla = () => (
  <div>
    <p className="title">Ola Gustafsson</p>
    <p className="subtitle">Hej!</p>
    <p>
      Här får du en inblick i några av Olas intressen och aktiviteter. Det
      gäller i synnerhet de som är IT-relaterade men bli inte förvånad om det
      dyker upp grejer som inte direkt har med systemutveckling eller
      programmering att göra.
    </p>
  </div>
);
