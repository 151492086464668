const klockord = {
  enTal: ["", "ett", "två", "tre", "fyra", "fem", "sex", "sju", "åtta", "nio"],
  tonTal: [
    "tio",
    "elva",
    "tolv",
    "tretton",
    "fjorton",
    "femton",
    "sexton",
    "sjutton",
    "arton",
    "nitton"
  ],
  tioTal: [
    "noll",
    "tio",
    "tjugo",
    "trettio",
    "fyrtio",
    "femtio",
    "sextio",
    "sjuttio",
    "åttio",
    "nittio"
  ],
  minuter: [
    "på slaget",
    "en minut över",
    "kvart över",
    "minuter över",
    "minuter i halv",
    "halv",
    "minuter över halv",
    "kvart i",
    "en minut i",
    "minuter i"
  ],
  timmar: ["midnatt", "middag", "på dagen", "på natten"]
};

export default klockord;
