import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/eS_Logo.png";

export const NavigationBar = () => {
  document.addEventListener("DOMContentLoaded", () => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener("click", () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" className on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  });
  return (
    <nav
      className="navbar is-fixed-top dimmed"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={logo} alt="logotype" />
        </a>
        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="damBurger"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="damBurger" className="navbar-menu">
        <div className="navbar-start">
          <div className="navbar-item">
            <div className="field">
              <div className="control">
                <NavLink
                  className="button"
                  activeClassName="is-info is-selected"
                  exact
                  to="/"
                >
                  <span className="icon">
                    <i className="fas fa-home" aria-hidden="true"></i>
                  </span>
                  <span>Hem</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="navbar-item">
            <div className="field">
              <div className="control">
                <NavLink
                  className="button"
                  activeClassName="is-info is-selected"
                  exact
                  to="/about"
                >
                  <span className="icon">
                    <i className="fas fa-info" aria-hidden="true"></i>
                  </span>
                  <span>Om</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="navbar-item">
            <div className="field">
              <div className="control">
                <NavLink
                  className="button"
                  activeClassName="is-info is-selected"
                  exact
                  to="/contact"
                >
                  <span className="icon">
                    <i className="fas fa-address-book" aria-hidden="true"></i>
                  </span>
                  <span>Kontakta</span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" href="/gaming">
              <span className="icon">
                <i className="fas fa-gamepad" aria-hidden="true"></i>
              </span>
              <span>Gaming</span>
            </a>
            <div className="navbar-dropdown">
              <a className="navbar-item" href="/gaming/speedtyping">
                <span className="icon">
                  <i className="fas fa-keyboard" aria-hidden="true"></i>
                </span>
                <span>SpeedTyping</span>
              </a>
              <a className="navbar-item" href="/gaming/minesweeper">
                <span className="icon">
                  <i className="fas fa-bomb" aria-hidden="true"></i>
                </span>
                <span>Mine Sweeper</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
