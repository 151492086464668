import React from "react";
import { Clock } from "../components/Clock";

export const Footer = () => (
  <footer className="box grid-xl">
    <div className="content has-text-centered">
      <p>
        <a href="https://eldstorpsolutions.se" alt="website">
          <strong>eLdsTorp Solutions</strong>
        </a>{" "}
        by Ola Gustafsson, Eldstorp 1, 573 97 TRANÅS, Sverige, 070 - 262 71 61,{" "}
        <a href="mailto:ola@eldstorpsolutions.se" title="Skicka ett mejl...">
          ola@eldstorpsolutions.se
        </a>
      </p>
      <div>
        <Clock />
      </div>
    </div>
  </footer>
);
