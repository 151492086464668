import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { NoMatch } from "./pages/NoMatch";
import { NavigationBar } from "./components/NavigationBar";
import { Hero } from "./components/Hero";
import { Footer } from "./components/Footer";

export const App = () => {
  return (
    <div>
      <Router>
        <NavigationBar />
        <Hero />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route component={NoMatch} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};
