import React from "react";

export const TileES = () => (
  <div>
    <p className="title">eLdsTorP Solutions</p>
    <p className="subtitle">företaget, est. 2004</p>
    <p>
      Från början var huvudinriktningen att sälja en egenutvecklad produkt,
      LoggBoken. Det tog inte lång tid innan det gled över på mer rena tjänster.
      Merparten av de senare åren har företaget dock fört en tynande tillvaro då
      Ola fokuserat på annat än entreprenörskap och marknadsföring.
    </p>
  </div>
);
