import React from "react";
import { Spring } from "react-spring/renderprops";
import ola from "../assets/Ola.png";

export const Contact = () => (
  <Spring
    from={{ opacity: 0, marginTop: 5000 }}
    to={{ opacity: 1, marginTop: 0 }}
  >
    {(props) => (
      <div className="container grid" style={props}>
        <div className="box dimmed">
          <article className="media">
            <div className="media-left">
              <figure className="image is-64x64">
                <img src={ola} alt="Ola" />
              </figure>
            </div>
            <div className="media-content">
              <p className="title">Kontakta mig</p>
              <p className="subtitle">
                <a href="mailto:ola@eldstorpsolutions.se">Ola Gustafsson</a>
              </p>
              <div className="content">
                eLdsTorP Solutions
                <br />
                Eldstorp 1<br />
                573 97 Tranås
                <br />
                070 - 262 71 61
                <br />
                <a href="mailto:ola@eldstorpsolutions.se">
                  ola@eldstorpsolutions.se
                </a>
                <br />
                <p>
                  <span className="icon">
                    <i className="fas fa-copyright"></i>
                  </span>
                  <span>Eldstorp, </span>
                  <time dateTime="2020-06-06">Juni 2020</time>
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    )}
  </Spring>
);
