import React from "react";

export const Hero = () => (
  <section className="hero grid-xl is-transparent is-bold is-small">
    <div className="hero-body">
      <div className="content">
        <h1 className="title">
          <br />
        </h1>
        <h2 className="subtitle">Livet är en resa...</h2>
      </div>
    </div>
  </section>
);
