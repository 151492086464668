import React from "react";

export const NoMatch = () => (
  <div className="container grid">
    <div className="box dimmed">
      <article className="media">
        <div className="media-left">
          <a href="/" title="Gå till eldstorpsolutions.se">
            <span class="icon is-large">
              <i class="fas fa-3x fa-home"></i>
            </span>
          </a>
        </div>
        <div className="media-content">
          <p className="title">Oops...</p>
          <p className="subtitle">
            <h2>Kan inte hitta sidan!</h2>
          </p>
          <div className="content">
            Gå till
            <a href="/" title="Gå till eldstorpsolutions.se">
              {" "}
              eldstorpsolutions.se
            </a>
            <br />
            <p>
              <span class="icon">
                <i class="fas fa-copyright"></i>
              </span>
              <span>Eldstorp, </span>
              <time datetime="2020-06-06">Juni 2020</time>
            </p>
          </div>
        </div>
      </article>
    </div>
  </div>
);
