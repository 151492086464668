import React from "react";
import { Spring } from "react-spring/renderprops";
import { TileOla } from "../Tiles/TileOla";
import { TileES } from "../Tiles/TileES";
import { TileLoggBok } from "../Tiles/TileLoggBok";

export const Home = () => (
  <Spring
    from={{ opacity: 0, marginTop: 5000 }}
    to={{ opacity: 1, marginTop: 0 }}
  >
    {(props) => (
      <div className="container grid" style={props}>
        <div className="tile is-ancestor dimmed">
          <div className="tile is-4 is-vertical is-parent">
            <div className="tile is-child box">
              <TileOla />
            </div>
            <div className="tile is-child box">
              <TileES />
            </div>
            <div className="tile is-child box">
              <TileLoggBok />
            </div>
          </div>
          <div className="tile is-vertical is-parent">
            <div className="tile is-child box">
              <p className="title">Nyheter</p>
              <div className="card">
                <div className="card-content">
                  <p className="subtitle" alt="Länk till gamingsajten">
                    Nytt spel på gaming-sajten
                  </p>
                  <p>
                    Gå till nya{" "}
                    <a
                      href="/gaming/minesweeper"
                      title="Besök gamingsajten eldstorpsolutions.se/gaming/minesweeper"
                    >
                      Mine Sweeper
                    </a>{" "}
                    och röj minor.
                  </p>
                  <p>
                    Starta med ett litet fält och röj alla minor. Spara dina
                    resultat. Gör om och klättra i resultatlistan. Slå dina
                    vänner.
                  </p>
                  <br />
                  <p>
                    <span className="tag">#reactjs</span>
                    <span className="tag">#css</span>
                    <span className="tag">#bulma</span>
                    <span className="tag">#restapi</span>
                    <span className="tag">#php</span>
                    <span className="tag">#mysql</span>
                  </p>
                  <p>
                    <span>Eldstorp, </span>
                    <time dateTime="2020-07-31">
                      den trettioförsta juli tjugohundratjugo
                    </time>
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-content">
                  <p className="subtitle" alt="Länk till gamingsajten">
                    Ny gaming-sajt
                  </p>
                  <p>
                    Besök den nya{" "}
                    <a
                      href="/gaming"
                      title="Besök gamingsajten eldstorpsolutions.se/gaming"
                    >
                      gamingsajten
                    </a>
                    .
                  </p>
                  <p>
                    Först ut är <a href="/gaming/speedtyping">Skrivtest</a>.
                  </p>
                  <p>
                    Testa din snabbhet på tangentbordet genom att skriva av
                    dagens citat. Spara dina resultat. Gör om och klättra i
                    resultatlistan. Nytt citat varje dag.
                  </p>
                  <br />
                  <p>
                    <span className="tag">#reactjs</span>
                    <span className="tag">#css</span>
                    <span className="tag">#bulma</span>
                    <span className="tag">#restapi</span>
                    <span className="tag">#php</span>
                    <span className="tag">#mysql</span>
                  </p>
                  <p>
                    <span>Eldstorp, </span>
                    <time dateTime="2020-07-16">
                      den sextonde juli tjugohundratjugo
                    </time>
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-content">
                  <p className="subtitle">Ny klocka</p>
                  <p>
                    Hemsidan (denna) för Eldstorp Solutions har uppdaterats med
                    en analog klocka. Se den i sidfoten...
                  </p>
                  <br />
                  <span className="tag">#reactjs</span>
                  <span className="tag">#css</span>
                  <span className="tag">#svg</span>
                  <p>
                    <span>Eldstorp, </span>
                    <time dateTime="2020-06-22">
                      den tjugoandra juni tjugohundratjugo
                    </time>
                  </p>
                </div>
              </div>
              <br />
              <div className="card">
                <div className="card-content">
                  <p className="subtitle">Ny hemsida</p>
                  <p>
                    Hemsidan (denna) för Eldstorp Solutions har fått sig en
                    rejäl genomkörare och begåvats med både ny teknik och ny
                    design.
                  </p>
                  <br />
                  <span className="tag">#reactjs</span>
                  <span className="tag">#bulma</span>
                  <span className="tag">#fontawesome</span>
                  <p>
                    <span>Eldstorp, </span>
                    <time dateTime="2020-06-06">
                      den sjätte juni tjugohundratjugo
                    </time>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </Spring>
);
