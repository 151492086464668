import React from "react";

export const TileLoggBok = () => (
  <div>
    <p className="title">LoggBoken</p>
    <p className="subtitle">produkten</p>
    <p>
      LoggBoken håller koll på maskiner och utrustningar och hjälper på så sätt
      tillverkande företag att öka sin lönsamhet. Vårt databasprogram är ett
      skarpt verktyg om ni jobbar med ständiga förbättringar i en eller annan
      form. Kaizen, TPM, Lean Production - LoggBoken ger bättre koll!
    </p>
  </div>
);
